export const useNaverLogin = () => {
  const { auth } = useSupabaseClient()

  /**
   * ! Naver Login Process !
   */
  const naverLoginProcess = async (email: string, password: string, name: string) => {
    const error = await signInNaver(email, password)

    if (error) {
      await signUpNaver(email, password, name)
      await signInNaver(email, password)
    }

    navigateTo('/confirm')
  }

  const signUpNaver = async (email: string, password: string, name: string) => {
    const { error } = await auth.signUp({
      email,
      password,
      options: {
        data: {
          full_name: name,
        },
      },
    })

    return error
  }

  const signInNaver = async (email: string, password: string) => {
    const { error } = await auth.signInWithPassword({
      email,
      password,
    })

    return error
  }

  return {
    naverLoginProcess,
  }
}
